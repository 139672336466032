import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import PageTemplate from "../templates/page"

const Page = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <PageTemplate
        imageAlt={frontmatter.imageAlt}
        slides={frontmatter.slides}
        menu={frontmatter.menu}
      />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        slug
        title
        imageAlt
        slides {
          content
          link
          fontSize
        }
        menu {
          label
          link
        }
      }
    }
  }
`;
